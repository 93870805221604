@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* global */
::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  cursor: pointer;
  background: #101112;
}
/* Handle */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #96969633;
  border-radius: 6px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #96969667;
}

.u-transition-color {
  @apply transition-colors duration-300;
}

.u-text-overflow {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}
* {
  @apply outline-none;
}
body {
  @apply font-inter overflow-x-hidden box-border bg-primary-500 text-white/80;
}

/* 
ul.rc-pagination {
  @apply w-full flex items-center justify-center;
}
ul.rc-pagination li {
  @apply border border-primary-200 rounded-4 text-primary-200 p-6 min-w-28 h-28 flex items-center justify-center cursor-pointer hover:text-primary-400 hover:border-primary-400;
}
ul.rc-pagination li.rc-pagination-item-active {
  @apply text-white bg-primary-200;
}

ul.rc-pagination li.rc-pagination-disabled {
  @apply cursor-not-allowed;
}
ul.rc-pagination li.rc-pagination-jump-next,
ul.rc-pagination li.rc-pagination-jump-prev {
  @apply border-transparent;
}
ul.rc-pagination li.rc-pagination-jump-next button::after,
ul.rc-pagination li.rc-pagination-jump-prev button::after {
  content: "\2022\2022\2022";
  display: block;
} */

.m-button-primary {
  @apply font-semibold text-14 px-24 py-12 rounded-4 outline-none disabled:bg-white/40 disabled:cursor-not-allowed flex items-center gap-8 bg-white/85 text-primary-500 hover:bg-white transition-colors duration-300;
}

.m-button-secondary {
  @apply font-semibold text-14 px-24 py-12 rounded-4 outline-none disabled:bg-primary-400 disabled:text-white/40 disabled:border-white/5 disabled:cursor-not-allowed flex items-center gap-8 bg-primary-300 text-white/80 border border-white/5 hover:text-white hover:border-white/20 transition-colors duration-300;
}

.m-label {
  @apply text-primary-200 text-14;
}

.m-input {
  @apply w-full border border-primary-200/30 rounded-4 bg-transparent p-10 text-14 u-transition-color focus:border-primary-200/50 placeholder:text-primary-100/20;
}

.g-bg-box {
  background: linear-gradient(0deg, #101112, #141516, #101112);
}
.g-text-pink {
  background: linear-gradient(90deg, #7168c0, #53429a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.g-border-pink {
  position: relative;
  padding: 1px !important;
  background: linear-gradient(180deg, #665ab2, #665ab200);
}
